import { IIconProps } from '@/types';
import * as React from 'react';

export const FacebookIcon: React.FC<IIconProps> = ({ customSize, className }) => {
	return (
		<svg
			className={`fill-current ${className}`}
			width="13"
			height="22"
			viewBox="0 0 13 22"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M11.7656 0.337935L8.99912 0.333496C5.8911 0.333496 3.88256 2.39419 3.88256 5.58366V8.00433H1.10101C0.860655 8.00433 0.666016 8.19919 0.666016 8.43955V11.9468C0.666016 12.1872 0.860877 12.3818 1.10101 12.3818H3.88256V21.2318C3.88256 21.4722 4.0772 21.6668 4.31756 21.6668H7.94669C8.18705 21.6668 8.38168 21.472 8.38168 21.2318V12.3818H11.634C11.8743 12.3818 12.069 12.1872 12.069 11.9468L12.0703 8.43955C12.0703 8.32415 12.0244 8.21362 11.9429 8.13195C11.8614 8.05027 11.7505 8.00433 11.6351 8.00433H8.38168V5.9523C8.38168 4.966 8.61672 4.46531 9.90152 4.46531L11.7651 4.46465C12.0053 4.46465 12.1999 4.26979 12.1999 4.02965V0.772933C12.1999 0.533018 12.0055 0.338379 11.7656 0.337935Z" />
		</svg>
	);
};

export const InstagramIcon: React.FC<IIconProps> = ({ customSize, className }) => {
	return (
		<svg className="fill-current" width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
			<path d="M15.78 0.333496H6.22109C2.97494 0.333496 0.333984 2.97458 0.333984 6.22072V15.7796C0.333984 19.0259 2.97494 21.6668 6.22109 21.6668H15.78C19.0264 21.6668 21.6673 19.0257 21.6673 15.7796V6.22072C21.6674 2.97458 19.0264 0.333496 15.78 0.333496ZM19.7746 15.7796C19.7746 17.9822 17.9827 19.774 15.7801 19.774H6.22109C4.01863 19.7742 2.22678 17.9822 2.22678 15.7796V6.22072C2.22678 4.01827 4.01863 2.22629 6.22109 2.22629H15.78C17.9825 2.22629 19.7745 4.01827 19.7745 6.22072V15.7796H19.7746Z" />
			<path d="M11.001 5.50342C7.96984 5.50342 5.50391 7.96935 5.50391 11.0005C5.50391 14.0315 7.96984 16.4973 11.001 16.4973C14.0321 16.4973 16.498 14.0315 16.498 11.0005C16.498 7.96935 14.0321 5.50342 11.001 5.50342ZM11.001 14.6044C9.01366 14.6044 7.3967 12.9877 7.3967 11.0004C7.3967 9.01291 9.01353 7.39609 11.001 7.39609C12.9884 7.39609 14.6052 9.01291 14.6052 11.0004C14.6052 12.9877 12.9883 14.6044 11.001 14.6044Z" />
			<path d="M16.7279 3.89844C16.3632 3.89844 16.005 4.04608 15.7474 4.30476C15.4886 4.56218 15.3398 4.92055 15.3398 5.28649C15.3398 5.65129 15.4887 6.00954 15.7474 6.26822C16.0048 6.52564 16.3632 6.67454 16.7279 6.67454C17.0938 6.67454 17.4509 6.52564 17.7096 6.26822C17.9683 6.00954 18.1159 5.65117 18.1159 5.28649C18.1159 4.92055 17.9683 4.56218 17.7096 4.30476C17.4522 4.04608 17.0938 3.89844 16.7279 3.89844Z" />
		</svg>
	);
};

export const YoutubeIcon: React.FC<IIconProps> = ({ customSize, className }) => {
	const size = customSize ? `${customSize}` : '24';
	const customClassName = className || 'h-6 w-6';
	return (
		<svg
			className={`fill-current ${customClassName}`}
			width={`${size}px`}
			height={`${size}px`}
			viewBox={`0 0 24 17`}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M22.98 1.59C22.329 0.432 21.6225 0.219 20.184 0.138C18.747 0.0405 15.1335 0 12.003 0C8.8665 0 5.2515 0.0405001 3.816 0.1365C2.3805 0.219 1.6725 0.4305 1.0155 1.59C0.345 2.7465 0 4.7385 0 8.2455C0 8.2485 0 8.25 0 8.25C0 8.253 0 8.2545 0 8.2545V8.2575C0 11.7495 0.345 13.7565 1.0155 14.901C1.6725 16.059 2.379 16.269 3.8145 16.3665C5.2515 16.4505 8.8665 16.5 12.003 16.5C15.1335 16.5 18.747 16.4505 20.1855 16.368C21.624 16.2705 22.3305 16.0605 22.9815 14.9025C23.658 13.758 24 11.751 24 8.259C24 8.259 24 8.2545 24 8.2515C24 8.2515 24 8.2485 24 8.247C24 4.7385 23.658 2.7465 22.98 1.59ZM9 12.75V3.75L16.5 8.25L9 12.75Z" />
		</svg>
	);
};
